$(document).ready(switchNavStyle);
$(window).on('resize', switchNavStyle);

let responsiveCheck;

function switchNavStyle() {
    if (window.innerWidth >= 1025 && !responsiveCheck) {
        responsiveCheck = true;
        $('.nav-touch-item').removeClass('hidden');
        $('.nav__button').removeClass('toggled');
        $('.submenu__nav, .submenu__child').addClass('hidden');
        $('.submenu__nav, .submenu__child').removeClass('active');
    } else if (window.innerWidth <= 1024 && responsiveCheck) {
        responsiveCheck = false;
        $('.nav-touch-item').removeClass('hidden');
        $('.nav__button').removeClass('toggled');
        $('.submenu__nav, .submenu__child').addClass('hidden');
        $('.submenu__nav, .submenu__child').removeClass('active');
    }
}

function fixedHeader() {
    let scroll = $(window).scrollTop();
    if (scroll > 90) {
        $('body').addClass('header--fix');
    } else {
        $('body').removeClass('header--fix');
    }
}

$(document).ready(function () {
    $('[data-link]').on('mouseover mouseout', function (e) {
        let image = document.querySelector('[data-image="' + $(this).attr('data-link') + '"]') !== null;
        if (image) {
            image = document.querySelector('[data-image="' + $(this).attr('data-link') + '"]');
        }
        let video = document.querySelector('[data-video="' + $(this).attr('data-link') + '"]') !== null;
        if (video) {
            video = document.querySelector('[data-video="' + $(this).attr('data-link') + '"]');
        }
        let bannerVideo = document.querySelector('.banner video') !== null;
        if (bannerVideo) {
            bannerVideo = document.querySelector('.banner video');
        }

        const evt = e.type;
        if (evt === 'mouseover') {
            if (image) {
                image.classList.add('active');
            }
            if (video) {
                if (bannerVideo) {
                    bannerVideo.pause();
                }

                video.play();
                video.classList.add('active');
            }
        }
        if (evt === 'mouseout') {
            if (image) {
                image.classList.remove('active');
            }
            if (video) {
                if (bannerVideo) {
                    bannerVideo.play()
                }

                video.pause();
                video.classList.remove('active');
            }
        }
    });

    $('.main__nav__item').on('mouseenter', function () {
        if (window.innerWidth > 1024) {
            if ($(this).children('.submenu__nav').hasClass('hidden')) {
                $('.header__hover__background').addClass('active');
                $(this).children('.main__nav__item__link').addClass('active');
                $(this).children('.submenu__nav').addClass('active');
                $(this).children('.submenu__nav').removeClass('hidden');
            }
        }
    });

    $('.submenu__nav__item').on('mouseenter', function () {
        if (window.innerWidth > 1024) {
            if ($(this).children('.submenu__child').hasClass('hidden')) {
                $(this).children('.submenu__child').addClass('active');
                $(this).children('.submenu__child').removeClass('hidden');
            }
        }
    });

    $('.main__nav__item').on('mouseleave', function () {
        if (window.innerWidth > 1024) {
            if ($(this).children('.submenu__nav').hasClass('active')) {
                $('.header__hover__background').removeClass('active');
                $(this).children('.main__nav__item__link').removeClass('active');
                $(this).children('.submenu__nav').addClass('hidden');
                $(this).children('.submenu__nav').removeClass('active');
            }
        }
    });

    $('.submenu__nav__item').on('mouseleave', function () {
        if (window.innerWidth > 1024) {
            if ($(this).children('.submenu__child').hasClass('active')) {
                $(this).children('.main__nav__item__link').removeClass('active');
                $(this).children('.submenu__child').addClass('hidden');
                $(this).children('.submenu__child').removeClass('active');
            }
        }
    });

    $('.nav-touch-item').on('click', function () {
        if (window.innerWidth > 1024) {
            $('.nav-touch-item').removeClass('hidden');
            if (!$(this).hasClass('hidden')) {
                if ($(this).parent().hasClass('main__nav__item')) {
                    $('.submenu__nav').addClass('hidden');
                    $(this).siblings('.submenu__nav').removeClass('hidden');
                    $(this).addClass('hidden');
                }

                if ($(this).parent().hasClass('submenu__nav__item')) {
                    $('.submenu__child').addClass('hidden');
                    $(this).siblings('.submenu__child').removeClass('hidden');
                    $(this).addClass('hidden');
                }
            }
        }
    });

    $('.nav__button').on('click', function () {
        if ($(this).siblings('.submenu__nav, .submenu__child').hasClass('hidden')) {
            $(this).addClass('toggled');
            $(this).siblings('.submenu__nav, .submenu__child').addClass('active');
            $(this).siblings('.submenu__nav, .submenu__child').removeClass('hidden');
        } else if ($(this).siblings('.submenu__nav, .submenu__child').hasClass('active')) {
            $(this).removeClass('toggled');
            $(this).siblings('.submenu__nav, .submenu__child').addClass('hidden');
            $(this).siblings('.submenu__nav, .submenu__child').removeClass('active');
        }
    });

    $('.menu__toggler').click(function () {
        var clicks = $(this).data('clicks');
        if (clicks) {
            $('body').removeClass('menu__open');
            $(this).html('Menu');
        } else {
            $('body').addClass('menu__open');
            $(this).html('Sluit');
        }
        $(this).data('clicks', !clicks);
    });

    if (window.innerWidth > 1024) {
        $(window).scroll(fixedHeader);
        fixedHeader();
    }
});
