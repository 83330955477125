import WOW from 'wow.js';

export default {
    init() {
        // JavaScript to be fired on all pages
        /* eslint-disable */
        const wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            live: true
        });
        wow.init();

        function is_touch_enabled() {
            return ('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0);
        }

        $(document).on('click', '.chat__image', function () {
            if (is_touch_enabled()) {
                $('.chat').toggleClass('active');
            }
        });

        $(document).on('click', 'body', function (e) {
            if ($(e.target).parents(".chat").length === 0) {
                $('.chat').removeClass('active');
            }
        });

        $('[data-fancybox="gallery"]').fancybox({
            baseClass: 'fancybox-custom-layout',
            thumbs: {
                autoStart: true,
                axis: 'x'
            }
        })

        $('.slick-slider').css('opacity', '1');

        $('.header__search__toggle').on('click', function () {
            $('.header__search').toggleClass('open');
            $('.header__search__input input').focus()
        });

        $('.footer__menu__toggle').on('click', function () {
            $(this).toggleClass('open');
            $(this).parent().siblings('ul').toggleClass('open');
        });

        $('.methods__list').slick({
            infinite: false,
            swipeToSlide: true,
            slidesToShow: 5,
            prevArrow: $('.methods__navigation--prev'),
            nextArrow: $('.methods__navigation--next'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
            ],
        });

        $('.edits__wrapper__item .block').on('mouseover mouseout', function (e) {
            let video = document.querySelector('.block [data-video="' + $(this).find('video').attr('data-video') + '"]') !== null;
            if (video) {
                video = document.querySelector('.block [data-video="' + $(this).find('video').attr('data-video') + '"]');
            }
            let bannerVideo = document.querySelector('.banner video') !== null;
            if (bannerVideo) {
                bannerVideo = document.querySelector('.banner video');
            }

            const evt = e.type;
            if (evt === 'mouseover') {
                if (video) {
                    if (bannerVideo) {
                        bannerVideo.pause();
                    }

                    video.play();
                }
            }
            if (evt === 'mouseout') {
                if (video) {
                    if (bannerVideo) {
                        bannerVideo.play()
                    }

                    video.pause();
                }
            }
        });


        /* eslint-disable */

        // $('.comments__icon').click(function () {
        //     $('#chat-application').addClass('chat-application-open');
        //     $(this).addClass('comments__icon--close');
        //     smartsupp('chat:open');
        // });
        //
        // $('.chat-application-open').click(function () {
        //     $('#chat-application').removeClass('chat-application-open');
        // });


        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
