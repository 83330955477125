export const singleBewerkingenData = {
    init() {
        // JavaScript to be fired on all pages
        /* eslint-disable */
        $('.slider__content--bewerkingen').slick({
            dots: false,
            arrows: true,
            fade: false,
            swipeToSlide: true,
            cssEase: 'linear',
            slidesToShow: 4,
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.slider__content--cases').slick({
            dots: false,
            arrows: true,
            fade: true,
            swipeToSlide: true,
            cssEase: 'linear',
            slidesToShow: 1,
            prevArrow: $('.slider__top__prev__cases'),
            nextArrow: $('.slider__top__next__cases')
        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
