import WOW from 'wow.js';


export default {
    init() {
        // JavaScript to be fired on the home page
        /* eslint-disable */

        const wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            live: true
        });
        wow.init();

        $('.slider__content--cases').slick({
            dots: false,
            arrows: true,
            fade: true,
            swipeToSlide: true,
            cssEase: 'linear',
            slidesToShow: 1,
            prevArrow: $('.slider__top__prev__cases'),
            nextArrow: $('.slider__top__next__cases')
        });

        $(document).on('touchstart click', '.materials__parent', function () {
            $('.materials__parent, .materials__holder').removeClass('active');

            let parentID = $(this).attr('data-parent');
            let matchingChild = $('.materials__holder[data-child="' + parentID + '"]');

            $(this).addClass('active');
            matchingChild.addClass('active');

            $('html, body').animate({
                scrollTop: matchingChild.offset().top - 100
            });

            matchingChild.find('.wow').each(function () {
                wow.show(this);
            });
        });

        $(document).ready(function () {
            if (window.innerWidth <= 1199) {
                $('.textusps__usp').slick({
                    centerMode: true,
                    swipeToSlide: true,
                    centerPadding: '16px',
                    prevArrow: $('.textusps__usp__slider--prev'),
                    nextArrow: $('.textusps__usp__slider--next'),
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                            }
                        }
                    ]
                });
            }
        });

        $(document).ready(function () {
            if (window.innerWidth <= 767) {
                $('.banner__usps').slick({
                    swipeToSlide: true,
                    arrows: false,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                });
            }
        });

        function sectionLeftSpacing() {
            $(".bewerkingen__wrapper, .materialen__wrapper, .front__bewerkingen__title h2, .front__materialen__title h2").attr("style", "padding-left:" + $('.footer .container-fluid').offset().left + "px;" + ";width:" + ($(window).width() + 100) + "px");
        }

        setTimeout(function () {
            sectionLeftSpacing();
        }, 200);

        $(window).resize(function () {
            sectionLeftSpacing();
        });

        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
